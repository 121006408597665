@let _defaultChoiceDisabled = defaultChoiceDisabled();

<button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
<mat-menu #menu="matMenu">
  @if (_defaultChoiceDisabled) {
    <button mat-menu-item (click)="onSaveForGraph()" data-testid="default-disabled-save">
      <mat-icon>save</mat-icon>
      <span>Set as default (graph only)</span>
    </button>
  } @else {
    <button mat-menu-item [matMenuTriggerFor]="saveMenu">
      <mat-icon>save</mat-icon>
      <span>Set as default</span>
    </button>
  }
  <button mat-menu-item [matMenuTriggerFor]="restoreMenu">
    <mat-icon>reset_settings</mat-icon>
    <span>Restore default</span>
  </button>
</mat-menu>

<mat-menu #saveMenu="matMenu">
  <button mat-menu-item (click)="onSaveDefault()" data-testid="save-default">
    <mat-icon>home</mat-icon>
    <span>For all graphs</span>
  </button>
  <button mat-menu-item (click)="onSaveForGraph()" data-testid="save-graph">
    <mat-icon>show_chart</mat-icon>
    <span>For this graph</span>
  </button>
</mat-menu>

<mat-menu #restoreMenu="matMenu">
  <button mat-menu-item (click)="onResetDefault()" data-testid="restore-default">
    <mat-icon>home</mat-icon>
    <span>
      Global default
      <span class="ml-1 text-lg font-bold" [style.visibility]="isGlobalDefault() ? 'visible' : 'hidden'">🗸</span>
    </span>
  </button>
  <button mat-menu-item (click)="onResetGraph()" data-testid="restore-graph">
    <mat-icon>show_chart</mat-icon>
    <span>
      Graph default
      <span class="ml-1 text-lg font-bold" [style.visibility]="isGraphDefault() ? 'visible' : 'hidden'">🗸</span>
    </span>
  </button>
</mat-menu>
