import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ShannonTestData } from '@features/data-validation/shared/interface/sampling-test';
import { MaterialModule } from '@modules/material.module';

import { StatusBannerComponent } from '../status-banner/status-banner.component';
import { ShannonTableCheckComponent } from './shannon-table-check/shannon-table-check.component';
import { ShannonTestGraphComponent } from './shannon-test-graph/shannon-test-graph.component';

@Component({
  selector: 'app-shannon-sampling-test',
  imports: [ShannonTestGraphComponent, StatusBannerComponent, MaterialModule, ShannonTableCheckComponent],
  templateUrl: './shannon-sampling-test.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShannonSamplingTestComponent {
  public filename = input.required<string>();
  public shannonTest = input.required<ShannonTestData>();

  public statusMessage = computed(() => {
    if (this.shannonTest().signalTooShort) {
      return this.signalTooShortMessage;
    }

    return `${this.samplingQuality()} ${this.antiAliasingFilterDetection()} ${this.aliasingDetection()}`;
  });

  public linearSuccess = computed((): boolean => {
    return this.shannonTest().linearTestSummary.result.succeeded || false;
  });

  public dbSuccess = computed((): boolean => {
    return this.shannonTest().dbTestSummary.result.succeeded || false;
  });

  public noiseSuccess = computed((): boolean => {
    return this.shannonTest().noiseTestSummary.result.succeeded || false;
  });

  public get signalTooShortMessage() {
    return 'The signal is too short for the test results to be relevant.';
  }

  public samplingQuality = computed(() => {
    return 'Signal a posteriori ' + (this.signalWellSampled() ? 'well sampled.' : 'badly sampled.');
  });

  public antiAliasingFilterDetection = computed(() => {
    return this.antiAliasingFilterDetected()
      ? 'Anti-aliasing filter detected' + (this.signalWellSampled() ? '.' : ', but')
      : 'No anti-aliasing filter detected.';
  });

  public aliasingDetection = computed(() => {
    if (this.signalWellSampled()) {
      return 'No aliasing of frequency components detected.';
    } else {
      if (this.antiAliasingFilterDetected()) {
        return (
          'not enough to avoid the aliasing of ' +
          (this.strongPowerAliasing() ? 'strong power' : '') +
          ' frequency components close to the Shannon frequency.'
        );
      } else {
        return (
          'Aliasing of ' +
          (this.strongPowerAliasing() ? 'strong power' : '') +
          ' frequency components close to the Shannon frequency observed.'
        );
      }
    }
  });

  public aliasingDetectionColor = computed(() => {
    if (this.signalWellSampled()) {
      return 'text-success';
    } else {
      if (this.antiAliasingFilterDetected()) {
        return 'text-warning';
      } else {
        return 'text-error';
      }
    }
  });

  public aliasingDetectionShort = computed(() => {
    if (this.signalWellSampled()) {
      return 'No aliasing';
    } else {
      if (this.antiAliasingFilterDetected()) {
        return `Possible ${this.strongPowerAliasing() ? 'strong power' : ''} aliasing `;
      } else {
        return `${this.strongPowerAliasing() ? 'strong power a' : 'A'}liasing`;
      }
    }
  });

  private antiAliasingFilterDetected = computed((): boolean => {
    return this.noiseSuccess();
  });

  public signalWellSampled = computed((): boolean => {
    return this.linearSuccess() && this.dbSuccess();
  });

  private strongPowerAliasing = computed((): boolean => {
    return !this.linearSuccess();
  });
}
