import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getObject<T, K extends string = string>(key: K): T | null {
    const s = this.getString(key);
    if (!s) {
      return null;
    }
    return JSON.parse(s) as T;
  }

  getString<K extends string = string>(key: K): string | null {
    return localStorage.getItem(key);
  }

  setObject<T, K extends string = string>(key: K, value: T) {
    this.setString(key, JSON.stringify(value));
  }

  setString<K extends string = string>(key: K, value: string) {
    localStorage.setItem(key, value);
  }

  remove<K extends string = string>(key: K) {
    localStorage.removeItem(key);
  }
}
