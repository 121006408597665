@let _hiddenHarmonicsCount = hiddenHarmonicsCount();

@if (_hiddenHarmonicsCount !== 0) {
  <div class="flex flex-row justify-center gap-4 px-8 py-4" headers>
    @let _many_hidden = _hiddenHarmonicsCount > 1;

    <mat-icon class="text-warning">warning</mat-icon>
    <span class="my-auto text-sm text-slate-800">
      {{ _hiddenHarmonicsCount }} of the selected families' peaks {{ _many_hidden ? 'are' : 'is' }} hidden because of
      the graph legend
    </span>
  </div>
}
