@let _sensorId = sensorSummary().id;

<div class="flex h-full w-full flex-col">
  <div class="mb-2 mt-2 flex flex-row justify-center">
    <mat-nav-list class="horizontal-nav-list flex rounded-3xl py-0 text-center font-bold">
      <a
        mat-list-item
        *ngFor="let tab of tabs"
        [class.bg-nav-selected]="isSelected(tab.id)()"
        class="min-w-36"
        [routerLink]="[]"
        [fragment]="tab.fragment">
        {{ tab.name }}
      </a>
    </mat-nav-list>
  </div>

  <div class="h-full w-full flex-grow">
    @if (_sensorId) {
      <app-sensor-signals
        id="Signals"
        [sensorId]="_sensorId"
        [hidden]="!signalsTabSelected()"
        [loading]="loading()"
        (navigateToGraph)="navigateToGraph()"
        (navigateToReport)="navigateToReport($event)"
        (navigateToReportInNewTab)="navigateToReportInNewTab($event)" />

      <app-sensor-trajectories
        id="Trajectories"
        class="h-full w-full"
        [sensorId]="_sensorId"
        [hidden]="!trajectoriesTabSelected()"
        (navigateToGraph)="navigateToGraph()" />

      <app-sensor-graphs
        id="Graph"
        [sensorId]="_sensorId"
        [hidden]="!graphsTabSelected()"
        class="h-full w-full"
        name="Sensor graph" />
    }
  </div>
</div>
