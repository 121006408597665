import { ValidationFlags } from '@features/data-validation/shared/interface/validation-flags';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SensorSignalsActions = createActionGroup({
  source: 'SensorSignals',
  events: {
    validationFlagsFetchRequested: props<{ signalId: AStrionSignalId }>(),
    validationFlagsFetchfailed: emptyProps(),
    validationFlagsFetched: props<{ signalId: AStrionSignalId; validationFlags: ValidationFlags }>(),

    flagSensorDataValidationAsDirtyRequested: props<{ sensorId: SensorId }>(),
    flagSensorPeakIdentificationAsDirtyRequested: props<{ sensorId: SensorId }>(),
    flagSensorTrackingAsDirtyRequested: props<{ sensorId: SensorId }>(),

    flagSensorAsDirtyFailed: emptyProps(),

    noAction: emptyProps(),

    signalReportNavigationRequested: props<{ signalId: AStrionSignalId; inNewTab?: boolean }>(),
    signalReportNavigationByTimestampRequested: props<{ timestamp: number; inNewTab?: boolean }>(),
    signalReportNavigationNotFound: emptyProps(),
  },
});
