import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-path-bar',
  templateUrl: './path-bar.component.html',
  imports: [MaterialModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .m3-headline-small-button {
        font-size: 24px !important;
        font-weight: 400 !important;
        --mdc-text-button-label-text-size: 24px;
        --mdc-text-button-label-text-weight: 400;
      }
      .m3-headline-small-icon {
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
      }
    `,
  ],
})
export class PathBarComponent {
  equipment = input<Equipment>();
  sensor = input<Sensor>();
  filename = input<string | null>(null);
  signalId = input<AStrionSignalId | null>();

  equipmentClicked = output<Equipment>();
  sensorClicked = output<Sensor>();
  homeClicked = output();

  loading = computed(() => this.equipment() === null);
  fullFilename = computed(() => this.filename() && this.loading());

  chosenProject = this.store.selectSignal(authFeature.selectChosenProject);

  constructor(
    private snackbar: MatSnackBar,
    private store: Store
  ) {}

  copyURL = () => {
    window.navigator.clipboard.writeText(this.generateURL());
    this.snackbar.open('URL copied to clipboard', 'Dismiss', { duration: 1000 });
  };

  generateURL(): string {
    const project = this.chosenProject();
    const url = `${window.origin}/${project?.name}`;

    const equipment = this.equipment();
    const sensor = this.sensor();
    const signalId = this.signalId();
    if (!equipment) {
      return url + '/operation';
    } else if (!sensor) {
      return url + `/equipment/${equipment.id}`;
    } else if (!signalId) {
      return url + `/sensor/${sensor.id}`;
    } else {
      return url + `//signals/report/${signalId}`;
    }
  }
}
