@if (signalId(); as signalId) {
  <app-path-bar
    [equipment]="equipment()"
    [sensor]="sensor()"
    [filename]="reportTitle()"
    [signalId]="signalId"
    class="flex-initial"
    (equipmentClicked)="navigateToEquipment($event)"
    (sensorClicked)="navigateToSensor($event)"
    (homeClicked)="navigateToRoot()">
    <button mat-icon-button matTooltip="Refresh report data" class="primary-button" (click)="onRefreshClicked()">
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Download signal"
      aria-label="Button that allows to download signal of the current report."
      [disabled]="loading()"
      class="primary-button"
      (click)="downloadSignal()">
      <mat-icon>download_file</mat-icon>
    </button>
  </app-path-bar>
  @if (loading()) {
    <mat-progress-bar class="flex-none" mode="indeterminate" />
  }
  <div class="mb-6 mt-2 flex h-[calc(100%-80px)] w-full flex-row">
    <app-report-stepper
      class="mb-[8px] h-[calc(100%-8px)] w-[64px]"
      [steps]="steps()"
      (stepClicked)="navigateToAnchor($event.anchorId)" />
    <app-scrollable-bordered-container
      #container
      class="flex flex-grow flex-col gap-6 overflow-x-hidden"
      id="report-content"
      (contentScrolled)="onScroll()"
      [fadeBottom]="true"
      [fadeTop]="true">
      <app-report-section [sectionData]="overviewSection" (expansionToggled)="onSectionExpansionToggled()">
        <app-report-overview (navigationToAnchorRequested)="navigateToAnchor($event)" />
      </app-report-section>
      <app-report-section [sectionData]="spectralAnalysisSection" (expansionToggled)="onSectionExpansionToggled()">
        <app-report-peak-identification />
      </app-report-section>
      <app-report-section [sectionData]="dataValidationSection" (expansionToggled)="onSectionExpansionToggled()">
        <app-report-data-validation />
      </app-report-section>
    </app-scrollable-bordered-container>
  </div>
}
