@let _shannonTest = shannonTest();
@let _signalWellSampled = signalWellSampled();
@let _linearSuccess = linearSuccess();
@let _noiseSucess = noiseSuccess();
@let _dbSuccess = dbSuccess();

<div class="flex w-full flex-col gap-2">
  <app-status-banner class="w-full flex-none" [status]="_shannonTest.status" [message]="statusMessage()">
    <table class="mx-auto my-2 table-auto">
      <thead>
        <tr>
          <th></th>
          <th class="px-6 py-3">Linear test</th>
          <th class="px-6 py-3">dB test</th>
          <th class="px-6 py-3">Morpho test</th>
          <th class="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Sampling quality</th>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="_linearSuccess" />
          </td>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="_dbSuccess" />
          </td>
          <td class="px-6 py-3 text-center"></td>
          <th class="px-6 py-3" [class]="_signalWellSampled ? 'text-success' : 'text-error'">
            {{ _signalWellSampled ? 'Well sampled' : 'Badly sampled' }}
          </th>
        </tr>
        <tr>
          <th>Anti-aliasing filter detection</th>
          <td class="px-6 py-3 text-center"></td>
          <td class="px-6 py-3 text-center"></td>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="_noiseSucess" />
          </td>
          <th class="px-6 py-3" [class]="_noiseSucess ? 'text-success' : 'text-error'">
            {{ _noiseSucess ? 'Filter detected' : 'No filter' }}
          </th>
        </tr>
        <tr>
          <th>Aliasing detection</th>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="_linearSuccess" />
          </td>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="_dbSuccess" />
          </td>
          <td class="px-6 py-3 text-center">
            @if (!_signalWellSampled) {
              <app-shannon-table-check [success]="_noiseSucess" />
            }
          </td>
          <th class="px-6 py-3" [class]="aliasingDetectionColor()">{{ aliasingDetectionShort() }}</th>
        </tr>
      </tbody>
    </table>
  </app-status-banner>

  <app-shannon-test-graph
    class="w-full"
    [filename]="filename()"
    [linearTest]="_shannonTest.linearTestSummary"
    [dbTest]="_shannonTest.dbTestSummary"
    [noiseTest]="_shannonTest.noiseTestSummary" />
</div>
