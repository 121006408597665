import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CsvExportOptions, GlGraphComponent, ImageExportOptions } from '@astrion-webtools/graph';
import { GraphCsvExportDialogComponent } from '@components/graph-csv-export-dialog/graph-csv-export-dialog.component';
import {
  ExportOptionsStorageMode,
  GraphImageExportDialogComponent,
  GraphImageExportDialogInput,
} from '@components/graph-image-export-dialog/graph-image-export-dialog.component';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class GraphExportService {
  private prefix = 'graph-export';

  constructor(
    private exportDialog: MatDialog,
    private storage: LocalStorageService
  ) {}

  factoryDefaultImageExport: ImageExportOptions = {
    title: '',
    width: 1280,
    height: 720,
    titleFont: '45px Arial',
    axisFont: '25px Arial',
    ticksFont: '18px Arial',
  };

  openImageExportDialog(
    graph: GlGraphComponent,
    componentOptions?: Partial<ImageExportOptions>,
    storageMode: ExportOptionsStorageMode = ExportOptionsStorageMode.Buttons,
    withLogo = true
  ): Promise<Blob | undefined> {
    const data: GraphImageExportDialogInput = {
      graph,
      componentOptions: componentOptions ?? {},
      storageMode,
      withLogo,
    };
    return firstValueFrom(
      this.exportDialog
        .open(GraphImageExportDialogComponent, {
          restoreFocus: false,
          role: 'dialog',
          autoFocus: '.focused',
          height: '80vh',
          width: '50vw',
          data,
        })
        .afterClosed()
    );
  }

  askForCsvExportOptions(xTitle: string): Promise<CsvExportOptions | undefined> {
    return firstValueFrom(
      this.exportDialog.open(GraphCsvExportDialogComponent, { restoreFocus: false, data: { xTitle } }).afterClosed()
    );
  }

  getDefaultOptions(): ImageExportOptions {
    let value = this.storage.getObject<ImageExportOptions>(`${this.prefix}-image-default`);
    if (!value) {
      value = this.resetDefaultOptions();
    }
    return value;
  }

  getGraphOptions(graphId: string): Partial<ImageExportOptions> {
    return this.storage.getObject(`${this.prefix}-image-graph-${graphId}`) ?? {};
  }

  updateDefaultOptions(options: Partial<ImageExportOptions>): ImageExportOptions {
    const newOptions: ImageExportOptions = {
      ...this.getDefaultOptions(),
      ...options,
    };
    this.saveDefaultOptions(newOptions);
    return newOptions;
  }

  updateGraphOptions(graphId: string, options: Partial<ImageExportOptions>) {
    const graphOptions = {
      ...this.getGraphOptions(graphId),
      ...options,
    };
    this.saveGraphOptions(graphId, graphOptions);
    return graphOptions;
  }

  resetDefaultOptions(): ImageExportOptions {
    return this.saveDefaultOptions(this.factoryDefaultImageExport);
  }

  resetGraphOptions(graphId: string): Partial<ImageExportOptions> {
    return this.saveGraphOptions(graphId, {});
  }

  deleteGraphOptions(graphId: string, options: string[] | string): Partial<ImageExportOptions> {
    const optionsSet = new Set(Array.isArray(options) ? options : [options]);
    const graphOptions = Object.fromEntries(
      Object.entries(this.getGraphOptions(graphId)).filter(([option]) => !optionsSet.has(option))
    ) as Partial<ImageExportOptions>;
    this.saveGraphOptions(graphId, graphOptions);
    return graphOptions;
  }

  private saveDefaultOptions(options: ImageExportOptions): ImageExportOptions {
    this.storage.setObject(`${this.prefix}-image-default`, options);
    return options;
  }

  private saveGraphOptions(graphId: string, options: Partial<ImageExportOptions>): Partial<ImageExportOptions> {
    this.storage.setObject(`${this.prefix}-image-graph-${graphId}`, options);
    return options;
  }
}
